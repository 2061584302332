<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :org-options="orgOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="filterRoleOptions"
      :status-options="statusOptions"
      class="d-none"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
            
            <a :href="$themeConfig.app.apiUrl + '/users-export'" download>
              <b-button
                class="ml-2"
                variant="outline-primary"
                >
                <span class="text-nowrap">Download All Users</span>
              </b-button>
            </a>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <div
          v-if="!requestDone"
          class="d-flex align-items-center justify-content-center position-absolute w-100 h-100"
          style="background-color: rgba(255, 255, 255, 0.7);"
      >
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- All column headers with no wrapping on text -->
        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <b-badge
              v-if="data.item.enabled"
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.enabled)}`"
              class="text-capitalize"
            >
              Active
            </b-badge>
            <b-badge
              v-else
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.enabled)}`"
              class="text-capitalize"
            >
              Inactive
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Org -->
        <template #cell(org)="data">
          <b-media vertical-align="center">
            <b-link
              v-if="data.item.organisation !== null"
              :to="{ name: 'orgs-view', params: { id: data.item.organisation.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.organisation.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <div v-if="sessionUserData.id !== data.item.id">
              <b-dropdown-item-button @click="openDeleteModal(data.item, deleteTargetItem)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item-button>
            </div>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>

      Are you sure you want to delete the user {{ deleteTargetItem.name }}?

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="deleteTarget(deleteTargetItem.id); cancel();"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BModal, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import store from '@/store'
// import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'

// Notification

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,
    BPagination,
    vSelect,
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id
      targetRef.name = item.name
      this.$bvModal.show('modalDelete')
    },
  },
  setup() {

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      requestDone,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    // Use toast
    const toast = useToast()

    const isAddNewUserSidebarActive = ref(false)

    const { roleOptions } = useUsersList()

    const sessionUserData = ref({})
    sessionUserData.value = useJwt.getUserData()

    const filterRoleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Manager', value: 'manager' },
      { label: 'User', value: 'user' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const deleteTargetItem = ref({ id: null, name: null })
    const orgOptions = ref([])

    const deleteTarget = targetId => {
      store.dispatch('app/deleteUser', { id: targetId })
        .then(() => {
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    store.dispatch('app/fetchOrgs', { perPage: 1000, sortBy: 'name' })
      .then(response => {
        orgOptions.value = response.data.results.map(item => ({ label: item.name, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          orgOptions.value = undefined
        }
      })

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      requestDone,

      orgOptions,

      deleteTargetItem,
      deleteTarget,

      // Filter
      // avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      filterRoleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      sessionUserData,

      $themeConfig,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
